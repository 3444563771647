import PluginRegistry from "../registry/registry"
import store from '../store/store'
import axios from 'axios'

window.plugins = {}

//Plugins padodās no ielādētā plugina main.js faila! 
function registerPlugin(id, plugin) {
  window.plugins[id] = plugin;
}

window.registerPlugin = registerPlugin

export async function initializePlugins() {
  //Get registered Webapp plugins

  //Load plugin

  //loadPlugin function (manifest)

  await store.dispatch("plugins/getPluginList").then((response) => {
    for (let i = 0; i < response.data.length; i++) {
      loadPlugin(response.data[i])
    }
  })
}

export function loadPlugin(manifest) {
  return new Promise((resolve, reject) => {
    function onLoad() {
      let plugin = window.plugins[manifest.id]
      plugin.initialize({ store: store, registry: new PluginRegistry(), axios: axios })
      resolve();
    }

    function onError() {
      reject()
    }

    let bundlePath = window.location.origin + manifest.webapp.bundle_path;

    const script = document.createElement('script');
    script.id = 'plugin_com.' + manifest.id;
    script.type = 'text/javascript';
    script.src = bundlePath;
    script.onload = onLoad;
    script.onerror = onError;

    document.getElementsByTagName('head')[0].appendChild(script);


    //loadedPlugins[manifest.id] = manifest;
  })
}
