<template>
  <v-app id="inspire">
    <v-scroll-y-transition mode="out-in" hide-on-leave>
      <router-view></router-view>
    </v-scroll-y-transition>
  </v-app>
</template>

<script>
export default {};
</script>
