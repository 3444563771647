import Vue from 'vue'
import Vuex from 'vuex'

// modules
import files from './files/store';
import toolbar from './toolbar/store';
import main from './main/store';
import fileDialogs from './files/dialogs/store';
import shareDialog from './files/dialogs/share';
import user from './user/store';
import upload from './snackbars/upload/store';
import alert from './snackbars/alert/store';
import office from './office/store';
import dragBar from './snackbars/drag/store';
import share from './share/store';
import admin from './admin/store';
import meta from './meta/store';
import workflows from './workflows/store';
import notifications from './snackbars/notifications/store';
import auth from './auth/store';
import groups from './groups/store';
import analytics from './analytics/store';
import activity from './activity/store';
import projects from './projects/store';
import license from './license/store';
import fileViewer from './fileViewer/store';
import registry from './registry/store';
import plugins from './plugins/store';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    files,
    toolbar,
    main,
    fileDialogs,
    shareDialog,
    user,
    upload,
    alert,
    office,
    dragBar,
    share,
    admin,
    meta,
    workflows,
    notifications,
    auth,
    groups,
    analytics,
    activity,
    projects,
    license,
    fileViewer,
    registry,
    plugins,
  }
})
