
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default ({
  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async getPluginList(context) {
      return new Promise((resolve) => {
        const options = {
          url: '/api/plugins/list',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
        };

        axios(options)
          .then((response) => {
            resolve(response);
          }).catch(() => {
            context.dispatch('alert/showAlert', {
              color: 'red',
              text: "Failed to retrieve plugin list"
            }, { root: true })
          });
      })
    },
  },
  namespaced: true,
})
