import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router/index'
import vuetify from './plugins/vuetify'
import axios from "axios"
import './scss/main.scss'
import { initializePlugins } from "./plugins/index.js";

Vue.config.devtools = false;

store.dispatch("license/getLicense");
store.dispatch("admin/getAppearances");

store.dispatch("user/checkSession").then(() => {
  store.commit("user/setIsAuthenticated", true);
}).catch(() => { }).finally(() => {

  axios.interceptors.request.use(function (config) {
    //TODO: Add only on this domain!
    config.headers.common['X-CSRF-TOKEN'] = store.getters["auth/getCSRF"];

    return config;
  });

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401) {
      store.dispatch('user/clearUserData');
      router.push({ name: 'login' });
    }

    return Promise.reject(error);
  });

  //plugins must be initialized before new Vue
  //global registration must take place before the root Vue instance is created (with new Vue)
  initializePlugins().then(() => {
    startApp()
  }).catch(() => {
    startApp()
  })
})

function startApp() {
  new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
