import Vue from 'vue'
import Vuex from 'vuex'
//import MyComponent from '../../../../plugins/com.twigex.example/webapp/src/components/MyComp.vue'

Vue.use(Vuex)

//Vue.component("my-comp", MyComponent)

export default ({
  state: {
    plugin: "my-comp",
    comp: null,
    appMenu: [],
    profileMenuButtons: [],
  },

  getters: {
    testPlugin() {
      return "TEST PLUGIN STORE"
    },
    getPlugin(state) {
      return state.plugin;
    },
    getAppMenu(state) {
      return state.appMenu;
    }
  },

  mutations: {
    registerMenuButton(state, newComponent) {
      Vue.component("my-comp", newComponent)
      state.plugin = "my-comp";
      state.comp = newComponent;
      console.log("registered plugin")
    },
    registerAppMenuIcon(state, component) {
      state.appMenu.push(component);
    }
  },

  actions: {
  },

  namespaced: true,
})

