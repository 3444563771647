import store from '../store/store'
import router from '../router/index'


function addPlugin(newComponent) {
  store.commit("registry/registerMenuButton", newComponent);
}

export default class PluginRegistry {
  //šis ir konstruktors, kur var padot variables kad objekts tiek taisīts
  constructor() { //id
    //this.id = id;
  }

  registerComponent(newComponent) {
    console.log(newComponent)
    return addPlugin(newComponent);
  }

  //Registers new icon for sidebar app menu
  //Accepts icon and router path to component and optional icon url.
  //If iconUrl is present then icon will be loaded from url
  registerNewAppIcon(appName, icon, routerPath, iconUrl) {
    if (icon == "" && iconUrl == "" || icon == "" && iconUrl == undefined) {
      console.log("Error: icon or icon url must be defined")
      return
    }
    store.commit("registry/registerAppMenuIcon", { app: appName, icon: icon, routerPath: routerPath, iconUrl: iconUrl })
  }


  //Pieliekas route pie main router
  // options.router.addRoute('main', {
  //     name: "plug",
  //     path: "plug",
  //     component: MyComp,
  //     meta: {
  //         requireAuth: true
  //     }
  // })
  registerRoute(routeObject) {
    //router.addRoute(obj)
    if (routeObject.root != undefined) {
      router.addRoute(routeObject.root, routeObject.route)
      return;
    }


    router.addRoute(routeObject.route)
  }


  //     // Register a component fixed to the top of the left-hand channel sidebar.
  // // Accepts a React component. Returns a unique identifier.
  // registerLeftSidebarHeaderComponent(component) {
  //     return dispatchPluginComponentAction('LeftSidebarHeader', this.id, component);
  // }
}
